@charset "UTF-8";

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right;
  background-size: 20px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAQAAAADQ4RFAAAABGdBTUEAALGPC/xhBQAAAAJiS0dEAP+Hj8y/AAAACXBIWXMAAABIAAAASABGyWs+AAAAB3RJTUUH5QEOAQQu7prc7gAAAGtJREFUOMtjYBgFowAZMKLxBRi0GFgY/qOo+MNwjeEDsiIWNE0sDNEMYQxMSCL/GFYx1BOyW5ZhOcMfhv9Q+IdhOYMsMU6WYVgG1faHYRmDDLE+hWgjSQvEkUsYlhDnMGQgyiBKqpZRMAwAAFciGQLPCIysAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTAxLTE0VDAxOjA0OjQ2LTA1OjAwTaFUvgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wMS0xNFQwMTowNDo0Ni0wNTowMDz87AIAAAAASUVORK5CYII=');
}

header nav {
  background-image: linear-gradient(to right, #1b2f52 0%, #2f6c90 100%) !important;
}

.loading-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0000001f;
}

.loading-spinner {
  div {
    position: absolute;
    left: 50%;
    right: 50%;
    top: 25%;
    width: 3rem;
    height: 3rem;
  }
}

.topnavbar .navbar-nav > .nav-item > .nav-link:hover,
.topnavbar .navbar-nav > .nav-item > .nav-link:focus,
.topnavbar .navbar-nav > .nav-item.show > .nav-link:hover,
.topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
  color: #dbdbdb;
}

.react-confirm-alert-overlay {
  z-index: 115 !important;
}

.Toastify__toast-container--top-right {
  top: 4em !important;
}

.modal-dialog-8 {
  max-width: 80%;
  margin: 1.75rem auto;
}

.modal-dialog-6 {
  max-width: 60%;
  margin: 1.75rem auto;
}

.dashboardGrid tr.expandable > td > .inner {
  margin: 15px;
  overflow: hidden;
  margin-left: 310px;
}
.expandRow {
  padding: 10px 10px 10px 300px;
}
.expandRow span.noDetails {
  margin-bottom: 0px 10px;
  padding-left: 40px;
}

.dashboardGrid .table-container th,
.dashboardGrid .table-container td {
  padding: 7px 5px;
}

.dashboardGrid tr.expandable > td > .inner {
  margin: 15px;
  overflow: hidden;
  margin-left: 310px;
}

.dashboardGrid .expandCollapseBtn {
  float: right;
  cursor: pointer;
}
.dashboardGrid .expandCollapseBtn:before {
  display: inline-block;
  content: '';
  height: 8px;
  width: 8px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(45deg);
}
.ReactTable.studentsGrid .rt-th {
  overflow: visible;
}
.map-card {
  height: 350px;
}

/* style 6 */

.input-file-6 + label {
  border: 1px solid #2f6c90;
  background-color: #f1e5e6;
}

.input-file-6:focus + label,
.input-file-6.has-focus + label,
.input-file-6 + label:hover {
  border-color: #2385a4;
}

.input-file-6 + label span {
  width: 200px;
  min-height: 2em;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  font-size: 15px;
}

.input-file-6 + label strong {
  height: 100%;
  color: #f1e5e6;
  background-color: #2f6c90;
  display: inline-block;
}

.input-file-6:focus + label strong,
.input-file-6.has-focus + label strong,
.input-file-6 + label:hover strong {
  background-color: #2385a4;
}

@media screen and (max-width: 50em) {
  .input-file-6 + label strong {
    display: block;
  }
}

.corona .react-datepicker-wrapper {
  width: 0px !important;
  height: 0px !important;
}

.corona .react-datepicker-popper {
  top: 28px !important;
  left: -40px !important;
}

.calendar-history {
  padding: 5px;
  text-align: left;
  display: inline;
  font-size: 20px;
  margin-right: 50px;
}

.icon-size {
  font-size: 25px;
}

.icon-size-small {
  font-size: 20px;
}

.temp-input {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0;
  width: 100%;
  min-height: 30px;
}

.temp-input-disabled {
  background-color: #cac5c5;
}

.img-fluid {
  width: 50px;
  float: left;
}

.brand-logo img,
.brand-logo-collapsed img {
  width: auto;
  height: 40px;
}

.brand-logo span {
  float: left;
  margin-top: 5px;
}

.hrLine::before {
  content: '';
  height: 1px;
  width: 80%;
  top: 10px;
  background: #cfdbe2;
  position: absolute;
  left: 120px;
}

h4.hrLine {
  position: relative;
}

.input-uid {
  display: inline;
  width: initial;
}

.cardWrapper .accordion__button {
  background-color: #fff;
  color: #656565;
  cursor: move;
  padding: 5px;
  text-align: left;
  border: none;
  font-size: 12px;
  font-weight: bold;
}

.cardWrapper .accordion__button:before {
  height: 6px;
  width: 6px;
  margin-right: 10px;
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.cardWrapper .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
  padding: 5px 0px;
  cursor: move;
}

.cardWrapper .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
  overflow: hidden;
  display: block;
  padding: 5px;
  border-bottom: 1px solid #cfdbe2;
  cursor: move;
}

.route-box {
  min-height: 450px;
}

.cardWrapper .smooth-dnd-container {
  height: 354px;
  overflow-y: auto;
}

.border-left-2 {
  border-left: 2px solid;
}

.rc-time-picker {
  display: block !important;
}

.rc-time-picker-input {
  height: 2.1875rem !important;
}

.datepicker {
  cursor: pointer;
  display: none;
}

.react-datepicker__triangle {
  left: 50px !important;
}
.pointer {
  cursor: pointer;
}
.text-15 {
  font-size: 15px;
}

.covid-active {
  padding-right: 8px;
  fill: #23b7e5;
}

.covid-icon {
  padding-right: 8px;
  &:hover {
    fill: #23b7e5;
  }
}

.page-title {
  font-size: 24px;
  margin-top: 15px;
}

.page-header {
  margin-top: 0;
  border-bottom: none;
  margin: 0 10px;
  margin-bottom: 20px;
  .float-right {
    font-size: 16px;
    color: #212529b8;
  }
}
select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%) !important;
  background-position: calc(100% - 20px) calc(1em + -1px), calc(100% - 15px) calc(1em + -1px), calc(100% - 2.5em) 0.5em !important;
  background-size: 5px 5px, 5px 5px, 1px 1.5em !important;
  background-repeat: no-repeat !important;
}
.rt-thead {
  font-weight: bold;
}

.email-verify-message {
  margin-top: -0.5em;
  color: #757575;
  letter-spacing: 1px;
  padding: 50px;
}

.success-email {
  background-color: #8bc34a;
  padding: 50px;
}

.failed-email {
  background-color: red;
  padding: 50px;
}

td.Error {
  color: red;
}

.mlr-120 {
  margin: 0px 120px;
}

.react-table-align-items-center {
  .rt-td {
    display: flex;
    align-items: center;
  }

  .rt-th {
    display: flex;
    align-items: center;
    div {
      width: 100%;
      text-align: center !important;
    }
  }
}

.sidebar-nav.sidebar-subnav {
  margin-left: 10px;
}

.danger {
  color: red;
}

.text-info {
  color: blue;
}

div.custom-icon-container:hover,
div.custom-icon-container:focus {
  opacity: 0.7;
}

.alert-warning {
  color: #854b16 !important;
  background-color: #ffe9d5 !important;
  border-color: #ffe0c4 !important;
}

.alert-danger {
  color: #7d2a2a !important;
  background-color: #fcdcdc !important;
  border-color: #fbcece !important;
}

::-webkit-file-upload-button {
  cursor:pointer;
}

input[type=file] {
  cursor:pointer;
}